import { Slot } from "@radix-ui/react-slot";
import { useCallback, useEffect, useRef, useState } from "react";

export type InfiniteScrollerProps = {
	children: React.ReactNode;
	loading: boolean;
	loadNext: () => void;
	itemsLength?: number;
};

export function InfiniteScroller({ children, loading, loadNext, itemsLength }: InfiniteScrollerProps) {
	const scrollListener = useRef(loadNext);

	useEffect(() => {
		scrollListener.current = loadNext;
	}, [loadNext]);

	const onScroll = useCallback(() => {
		const mainElement = document.querySelector("main");
		if (!mainElement) return;

		const { scrollTop, clientHeight, scrollHeight } = mainElement;
		console.info("onScroll", { scrollTop, clientHeight, scrollHeight });

		const scrollThreshold = 800; // pixels from bottom

		if (scrollHeight - scrollTop - clientHeight < scrollThreshold && !loading) {
			scrollListener.current();
		}
	}, [loading]);

	useEffect(() => {
		const element = document.querySelector("main");
		element?.addEventListener("scroll", onScroll);

		return () => {
			element?.removeEventListener("scroll", onScroll);
		};
	}, []);

	return <Slot>{children}</Slot>;
}
